<template>
  <div>
    <subscription-edition
      v-if="hasNetworkRole"
      :is-disabled="isDisabled"
      :subscription-plan="subscriptionPlan"
    />
    <documents
      :is-disabled="isDisabled"
      :subscriptionPlan="subscriptionPlan"
      @on:recent-docs:updated="$emit('on:recent-docs:updated', $event)"
    />
    <rates
      :is-disabled="isDisabledPrices"
      :validator="validator"
      :violations="violations"
      :subscriptionPlan="subscriptionPlan"
    />
    <!-- <access-control class="hidden"/> // TODO when back is ready -->
  </div>
</template>
<script>
import {ROLE_CLUB_NETWORK_OWNER} from "@/utils/role";

export default {
  components: {
    Rates: () => import('./Rates'),
    AccessControl: () => import('./AccessControl'),
    Documents :()=> import('../subscription-documents/Documents'),
    SubscriptionEdition :()=> import('../subscription-edition/Index'),
  },
  props: {
    subscriptionPlan: {
      type: Object,
      default: () => {
      }
    },
    violations: {
      type: Array,
      default: () => [],
    },
    validator: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: true
    },
    isDisabledPrices: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasNetworkRole() {
      return this.$hasRole(ROLE_CLUB_NETWORK_OWNER);
    }
  }
}
</script>
